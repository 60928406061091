import revive_payload_client_WQVu4TNMcH from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_CsYqqFqade from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Or3d3OYnd8 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_73JqLCPt6k from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_EA5eCVA2xh from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_lU5HOtrCEU from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_g1bHdkgU9p from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_0avTvf4qem from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/midea/miraco-website/.nuxt/components.plugin.mjs";
import prefetch_client_azBxk651Ef from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.0_encoding@0.1.13_eslint@8.57.0_idb-keyval_6afgxnjwnl2wtpbinpkcsymgr4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mi3BLgv0TD from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+device@3.2.2_@parcel+watcher@2.4.1_@playwright+test@1.45.3_@types+node@22.0.0_encodin_brhlj4em6bowaoyrx65vh3gzqa/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import sentry_client_KayOyvtMmq from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+sentry@1.0.0-beta.17_encoding@0.1.13_magicast@0.3.4_rollup@4.19.1_vue@3.4.34_typescript@5.5.4_/node_modules/@robustastudio/sentry/dist/runtime/plugins/sentry.client.mjs";
import pwa_client_r0qrMtAZcO from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@vite-pwa+nuxt@0.5.0_@vite-pwa+assets-generator@0.2.4_magicast@0.3.4_rollup@4.19.1_vite@5.3.5_qad7nukcqfrsbousdtiyrc7iae/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_nYJ6s6st68 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt-delay-hydration@1.3.5_magicast@0.3.4_rollup@4.19.1/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_M2aJwmMdCJ from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+e-commerce@1.45.0-develop.25_encoding@0.1.13_magicast@0.3.4_rollup@4.19.1_vite_ulmxortezdnsr6czig3v4imxrq/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_V0M7GOUZD6 from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@robustastudio+e-commerce@1.45.0-develop.25_encoding@0.1.13_magicast@0.3.4_rollup@4.19.1_vite_ulmxortezdnsr6czig3v4imxrq/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_HeBlKQO3rH from "/builds/robustastudio/midea/miraco-website/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.4_rollup@4.19.1_vue@3.4.34_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/midea/miraco-website/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/midea/miraco-website/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/midea/miraco-website/plugins/setup.ts";
export default [
  revive_payload_client_WQVu4TNMcH,
  unhead_CsYqqFqade,
  router_Or3d3OYnd8,
  payload_client_73JqLCPt6k,
  navigation_repaint_client_EA5eCVA2xh,
  check_outdated_build_client_lU5HOtrCEU,
  view_transitions_client_g1bHdkgU9p,
  chunk_reload_client_0avTvf4qem,
  components_plugin_KR1HBZs4kY,
  prefetch_client_azBxk651Ef,
  plugin_mi3BLgv0TD,
  sentry_client_KayOyvtMmq,
  pwa_client_r0qrMtAZcO,
  nuxt_plugin_nYJ6s6st68,
  plugin_vJMX8vIrJ6,
  gtm_client_M2aJwmMdCJ,
  ssr_caching_V0M7GOUZD6,
  i18n_HeBlKQO3rH,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]